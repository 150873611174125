import React from 'react';
import { Modal, notification } from 'antd';
import { RouteContext } from '../../../hoc/Route';
import { UserContext } from '../../../hoc/User';
import { LocalizationContext } from '../../../hoc/Localization';
import { SettingsContext } from '../../../hoc/Settings';
import Button from '../../Button';
import Input from '../../Input';
import styles from '../Login/styles.less';

const EmailLogin = () => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');

  const {
    queryParams: { url, token },
  } = React.useContext(RouteContext);
  const { emailRegister, user, loginIfExists } = React.useContext(UserContext);
  const { t } = React.useContext(LocalizationContext);
  const {
    domainSettings: { domain },
  } = React.useContext(SettingsContext);
  const [visible, setVisible] = React.useState(null);

  React.useEffect(() => {
    async function checkToken() {
      if (token && token.length > 0) {
        const data = await loginIfExists(token);

        if (data && !data.firstName) {
          setVisible(true);
        }

        if (!data) {
          notification.warning({
            message: (
              <div>
                <h3>Invalid token!</h3>
                <span>Tokens are getting expired in 1 hour.</span>
              </div>
            ),
          });
        }
      }
    }

    checkToken();
  }, []);

  React.useEffect(() => {
    if (token && user && !user.isNewUser && !user.isRewarded) {
      window.location.replace(url || '/');
    }
  }, [user, visible])

  const hideModal = () => {
    setVisible(false);
  };

  const register = async data => {
    if (!data.firstName) {
      notification.error({
        message: t('register.firstname.required'),
      });
      return;
    }
    if (!data.lastName) {
      notification.error({
        message: t('register.lastname.required'),
      });
      return;
    }
    await emailRegister(data);
    hideModal();
  };


  return (
    <>
      <Modal
        title={t('email.register.text')}
        visible={visible}
        className={`${styles.containerModal} ${styles.loginModalBackground}`}
        wrapClassName={styles.loginModal}
        onCancel={hideModal}
        footer={[]}
      >
        <section>
          <div data_auth="auth_email">
            <Input
              version="long"
              placeholder={t('email.first.name.text')}
              size="large"
              name="email.auth.firstName"
              className={styles.emailInput}
              defaultValue={firstName}
              onInput={e => setFirstName(e.target.value || null)}
            />
            <Input
              version="long"
              placeholder={t('email.last.name.text')}
              size="large"
              name="email.auth.lastName"
              className={styles.emailInput}
              defaultValue={lastName}
              onInput={e => setLastName(e.target.value || null)}
            />
          </div>
          <Button
            type="primary"
            className={styles.kepLoginEmail}
            name="email_auth_button"
            onClick={() => register({ token, firstName, lastName })}
          >
            {t('email.signup.button.text')}
          </Button>
        </section>
      </Modal>
    </>
  );
};

export default EmailLogin;
