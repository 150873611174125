import React from 'react';
import { Input as AntInput } from 'antd';
import PropTypes from 'prop-types';
import styles from './styles.less';

const propsByVersion = {
  default: {
    className: styles.default,
  },
  long: {
    className: styles.long,
  },
  short: {
    className: styles.short,
  },
};

const Input = ({
  version,
  className,
  defaultValue,
  value,
  autoComplete,
  onInput,
  onChange,
  onClick,
  placeholder,
  size,
  key,
  settings,
  style,
  disabled,
  name,
  maxLength,
}) => {
  const { className: propsClassName = '', ...props } = propsByVersion[version];
  return (
    <AntInput
      {...props}
      className={`${propsClassName} ${className}`}
      defaultValue={defaultValue}
      value={value}
      onClick={onClick}
      disabled={disabled}
      onChange={onChange}
      onInput={onInput}
      autoComplete={autoComplete}
      placeholder={placeholder}
      size={size}
      name={name}
      key={key}
      maxLength={maxLength}
      {...settings}
      style={style}
    />
  );
};

Input.propTypes = {
  version: PropTypes.oneOf(['default', 'long', 'short']),
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  key: PropTypes.string,
  disabled: PropTypes.bool,
  settings: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.object,
  name: PropTypes.string,
  maxLength: PropTypes.number,
};

Input.defaultProps = {
  version: 'default',
  className: '',
  value: undefined,
  defaultValue: '',
  autoComplete: '',
  placeholder: '',
  name: '',
  key: '',
  size: 'middle',
  settings: {},
  style: {},
  maxLength: 10000,
  disabled: false,
  onInput: () => {},
  onChange: () => {},
  onClick: () => {},
};

export default Input;
